/* global angular */

'use strict';

angular.module('managerApp').factory('assistancePopup', ($mdDialog, Auth, assistances) => {

    let assistanceMotifs = null;
    function init() {
        if (Auth.hasPermissions('ASSISTANCE')) {
            assistances.getMotifs().then((data) => {
                assistanceMotifs = data;
            });
        }
    }

    init();

    return {
        show: (userId = null, procedureId = null) => {
            return new Promise((resolve, reject) => {
                $mdDialog.show({
                    templateUrl: 'components/assistance/assistance.html',
                    parent: angular.element(document.body),
                    fullscreen: true,
                    locals: {
                        userId: userId,
                        procedureId: procedureId
                    },
                    controller: ($scope, $mdDialog, userId, procedureId) => {
                        $scope.isClient = true;
                        if (procedureId === null) {
                            $scope.isClient = false;
                        }
                        $scope.assistance = {};
                        $scope.assistance.UserId = userId;
                        $scope.assistance.ProcedureId = procedureId;
                        $scope.assistance.comment = null;
                        $scope.assistance.motif = null;
                        $scope.assistance.type = 'none';
                        $scope.defaultMotifs = assistanceMotifs;
                        $scope.onTypeSelect = () => {
                            if ($scope.type + '' === '0') {
                                $scope.assistance.motif = null;
                            }
                        };
                        $scope.closeDialogOnly = () => {
                            $mdDialog.hide();
                        }
                        $scope.closeDialog = (confirm) => {
                            $mdDialog.hide();
                            if (confirm) {
                                assistances.add($scope.assistance).then((res) => {
                                    resolve(res);
                                }).catch((err) => {
                                    reject(err);
                                });
                            } else {
                                reject(null);
                            }//
                        };
                    }
                }).then(() => {
                }, () => {
                });
            });
        }
    };
});